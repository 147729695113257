define("discourse/plugins/discourse-custom-category/discourse/templates/components/categories-continent-menu", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="continent-menu">
    <ul>
      {{#each this.categories as |category|}}
        <li class="continent-{{category.slug}}">
          <a href="#{{category.slug}}">
                <span class="continent-img ">
                  <img src="{{category.uploaded_logo.url}}" alt="logo">
                </span>
            <span class="continent-name">
              {{category.name}}
            </span>
          </a>
        </li>
      {{/each}}
    </ul>
  </div>
  */
  {
    "id": "9vrGxzXO",
    "block": "[[[10,0],[14,0,\"continent-menu\"],[12],[1,\"\\n  \"],[10,\"ul\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"categories\"]]],null]],null],null,[[[1,\"      \"],[10,\"li\"],[15,0,[29,[\"continent-\",[30,1,[\"slug\"]]]]],[12],[1,\"\\n        \"],[10,3],[15,6,[29,[\"#\",[30,1,[\"slug\"]]]]],[12],[1,\"\\n              \"],[10,1],[14,0,\"continent-img \"],[12],[1,\"\\n                \"],[10,\"img\"],[15,\"src\",[29,[[30,1,[\"uploaded_logo\",\"url\"]]]]],[14,\"alt\",\"logo\"],[12],[13],[1,\"\\n              \"],[13],[1,\"\\n          \"],[10,1],[14,0,\"continent-name\"],[12],[1,\"\\n            \"],[1,[30,1,[\"name\"]]],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[1]],null],[1,\"  \"],[13],[1,\"\\n\"],[13]],[\"category\"],false,[\"each\",\"-track-array\"]]",
    "moduleName": "discourse/plugins/discourse-custom-category/discourse/templates/components/categories-continent-menu.hbs",
    "isStrictMode": false
  });
});